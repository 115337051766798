





























































import Error from '@/components/Error.vue';
import { BaseComponent, Component } from '@/components/BaseComponent';

@Component({
  components: {
    Error,
  },
})
export default class LoginPage extends BaseComponent {
  credentials = {
    identifier: '',
    password: '',
    remember: false,
  };

  get settings() {
    return this.store.getSettings();
  }

  get startData() {
    return this.session.getStartData();
  }

  get changePasswordLink() {
    return { name: this.Pages.SendResetPasswordCode };
  }

  async login() {
    const result = await this.pds.login(this.credentials);
    if (result.isSuccess()) {
      this.router.changePage(result);
    } else {
      this.error = result.error;
    }
  }

  async loginWith() {
    const result = await this.pds.getLoginWithLink();
    if (result.isSuccess()) {
      this.router.changePage(result);
    } else {
      this.error = result.error;
    }
  }
}
